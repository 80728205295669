// import { LabeledTextField } from "src/core/components/LabeledTextField"
// import { Form, FORM_ERROR } from "src/core/components/Form"
// import signup from "src/auth/mutations/signup"
// import { Signup } from "src/auth/schemas"
// import { useMutation } from "@blitzjs/rpc"
import styles from "../styles/Home.module.css"
import {
  Button,
  useToast,
  Box,
  Center,
  Image,
  CloseButton,
  Flex,
  Spacer,
  FormControl,
  FormLabel,
  FormHelperText,
} from "@chakra-ui/react"
import React from "react"
import { useWhichContext } from "../Login/Login"
import {Input} from "@chakra-ui/react";
import {gql, useMutation, useQuery} from '@apollo/client';
import {apolloClient} from "../App";
import {AuthInput, CreateUserInput} from "../../types/generated"
import {useNavigate} from "react-router-dom";
import Cookies from "js-cookie";

const CREATE_USER = gql`
    mutation createUser($newUser: CreateUserInput!) {
        createUser(createUser: $newUser) {
            id
            email
            name
            role
        }
    }
`
const AUTH_USER = gql`
    mutation login($login: AuthInput!) {
        login(login: $login) {
            access_token
        }
    }
`
const ALL_USERS = gql`
    query allUsers {
        allUsers {
            name
        }
    }
`

export const SignupForm = () => {
  const storedJwt = localStorage.getItem('token');
  const storedEmail = localStorage.getItem('email');
  const [jwt, setJwt] = React.useState(storedJwt || null);
  const [emailCookie, setEmailCookie] = React.useState(storedEmail || null);
  const [firstName, setFirstName] = React.useState('')
  const [lastName, setLastName] = React.useState('')
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [cancel, setCancel] = React.useState(0)
  const { which, setWhich } = useWhichContext()
  const toast = useToast()
  const navigate = useNavigate();
  const [createUser, createUserResponse] = useMutation(CREATE_USER)
  const [authUser, session] = useMutation(AUTH_USER);
  const handleCancel = () => {
    toast.closeAll()
    if (cancel === 2) {
      toast({
        duration: 3000,
        isClosable: true,
        position: "top",
        render: ({ onClose }) => (
          <Box
            color="white"
            p={3}
            bg="blue.500"
            width={"min-content"}
            __css={{ borderRadius: "var(--chakra-radii-md)" }}
            minW={"25vw"}
          >
            <Flex>
              <Spacer />
              <CloseButton onClick={() => onClose()} size={"sm"} />
            </Flex>
            <Center>Okay Then...</Center>
            <Center>
              <Image src={"bocchi-huh.gif"} alt={""} />
            </Center>
          </Box>
        ),
      })
      setWhich("")
    }
    if (cancel === 1) {
      toast({
        duration: 3000,
        isClosable: true,
        position: "top",
        render: ({ onClose }) => (
          <Box
            color="white"
            p={3}
            bg="blue.500"
            width={"min-content"}
            __css={{ borderRadius: "var(--chakra-radii-md)" }}
            minW={"25vw"}
          >
            <Flex>
              <Spacer />
              <CloseButton onClick={() => onClose()} size={"sm"} />
            </Flex>
            <Center>Think of the Bocchi!</Center>
            <Center>
              <Image src={"bocchi-trash.gif"} alt={""} />
            </Center>
          </Box>
        ),
      })
      setCancel(2)
    }
    if (cancel === 0) {
      toast({
        duration: 3000,
        isClosable: true,
        position: "top",
        render: ({ onClose }) => (
          <Box
            color="white"
            p={3}
            bg="blue.500"
            width={"min-content"}
            __css={{ borderRadius: "var(--chakra-radii-md)" }}
            minW={"25vw"}
          >
            <Flex>
              <Spacer />
              <CloseButton onClick={() => onClose()} size={"sm"} />
            </Flex>
            <Center>Dont Go!</Center>
            <Center>
              <Image src={"bocchi-no.gif"} alt={""} />
            </Center>
          </Box>
        ),
      })
      setCancel(1)
    }
  }
  const { loading, error, data } = useQuery(ALL_USERS);
  const handleSignup = () => {
    if (firstName === '' || lastName === '' || email === '' || password === '') {
      return;
    }
    const name = firstName + ' ' + lastName;
    const user: CreateUserInput =  {
      name: name,
      firstName: firstName,
      lastName: lastName,
      email: email,
      password: password,
    }
    createUser({
      variables: {
        newUser: user
      },
      onCompleted(data) {
        console.log(data)
        setJwt(data.access_token);
        Cookies.set('token', data.access_token);
        setEmailCookie(user.email);
        Cookies.set('email', user.email);
        console.log("signing in...")
        // createUserResponse.reset();
        // navigate('/home', );
        const auth: AuthInput = {
          email: email,
          password: password,
        }
        authUser({
            variables: {
              login: auth
            },
            onCompleted(data) {
              setJwt(data.login.access_token);
              Cookies.set('token', data.login.access_token);
              setEmailCookie(email);
              Cookies.set('email', email);
              navigate('/home');
            },
            onError(error) {
              console.log(error.message)
              toast({
                title: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
                position: 'top',
              })
            }
          }
        )
      },
      onError(error) {
        console.log("failed: " + error.message)
        toast({
          title: error.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        })
        createUserResponse.reset();
      }
    })
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.buttonContainer}>
          <div>
            <h1>Create an Account</h1>

            <FormControl isRequired>
              <FormLabel>First Name</FormLabel>
              <Input type='text' value={firstName} onChange={(e) => setFirstName(e.target.value)}/>
              <FormHelperText>Who you is?</FormHelperText>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input type='text' value={lastName} onChange={(e) => setLastName(e.target.value)}/>
              <FormHelperText>No, really though.</FormHelperText>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Email address</FormLabel>
              <Input type='email' value={email} onChange={(e) => setEmail(e.target.value)}/>
              <FormHelperText>I'll never share your email. Honestly don't even want it...</FormHelperText>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Create a Password</FormLabel>
              <Input type='password' value={password} onChange={(e) => setPassword(e.target.value)}/>
              <FormHelperText>Yes, it's encrypted. No, I won't write down how. </FormHelperText>
            </FormControl>
            <div style={{ marginTop: "1rem" }}>
              <Button className={styles.button} __css={{}} onClick={() => handleSignup()}>
                <strong>Submit</strong>
              </Button>
            </div>
            <div style={{ marginTop: "1rem" }}>Not feeling it:</div>
            <div style={{ marginTop: "1rem" }}>
              <Button className={styles.button} __css={{}} onClick={() => setWhich("login")}>
                <strong>Login</strong>
              </Button>
              <Button className={styles.button} __css={{}} onClick={() => handleCancel()}>
                <strong>Cancel</strong>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignupForm
