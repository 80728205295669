import styles from "../styles/Home.module.css";
import React, { Suspense } from "react";
import {
  Avatar,
  Box,
  Button,
  Center,
  extendTheme,
  Flex,
  Heading,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spacer,
  Text,
} from "@chakra-ui/react";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  AuthInput,
  GetJwtInput,
  GetUserInput,
  Jwt,
  User,
} from "../../types/generated";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const GET_USER = gql`
  mutation getUser($getUser: GetUserInput!) {
    getUser(getUser: $getUser) {
      id
      email
      name
      role
      picture
    }
  }
`;
export const CustomAvatar = ({
  email,
  id,
  name,
  role,
  jwt,
  picture,
}: {
  email: String;
  id: String;
  name: String;
  role: String;
  jwt?: String;
  picture?: string;
}) => {
  const navigate = useNavigate();

  console.log(email, id, name, role, jwt, picture);

  return (
    <>
      <Menu>
        <MenuButton as={Button} variant={"link"}>
          <Avatar name={"" + name} src={picture} mt={10} width={"100px"} height={"100px"}/>
        </MenuButton>
        <MenuList>
          <Flex>{id}</Flex>
          <Flex>{name}</Flex>
          <Flex>{role}</Flex>
          <MenuItem
            onClick={() => {
              navigate("/home");
            }}
          >
            Home
          </MenuItem>
          <MenuItem onClick={() => {
            navigate("/profile");
          }}
          >
            Profile
          </MenuItem>
          <MenuItem
            onClick={() => {
              Cookies.remove("token");
              Cookies.remove("email");
              navigate("/");
            }}
          >
            Logout
          </MenuItem>
          <MenuDivider />
          <MenuItem>Report an Issue</MenuItem>
        </MenuList>
      </Menu>
    </>
  );
};
const BlogLink = () => {
  const [showText, setShowText] = React.useState(false);
  return (
    <Box
      onPointerEnter={() => setShowText(true)}
      onPointerLeave={() => setShowText(false)}
      as={"button"}
      __css={{
        backgroundImage: "monika.gif",
        backgroundSize: "224px 224px",
        backgroundRepeat: "no-repeat",
        boxSize: "3xs",
        borderRadius: "full",
      }}
    >
      <Link href={"/blog"} _hover={{ textDecoration: "none" }}>
        {showText && <Text variant={"noTextBlur"}>Blog</Text>}
        {showText && <Text variant={"greenBold"} marginLeft={"48.5px"}>Blog</Text>}
      </Link>
    </Box>
  );
};
const GithubLink = () => {
  const [showText, setShowText] = React.useState(false);
  return (
    <Box
      onPointerEnter={() => setShowText(true)}
      onPointerLeave={() => setShowText(false)}
      as={"button"}
      __css={{
        backgroundImage: "https://i.pinimg.com/originals/ac/b3/51/acb3513e5a2664ba59bec11222863a40.jpg",
        backgroundSize: "224px 224px",
        backgroundRepeat: "no-repeat",
        boxSize: "3xs",
        borderRadius: "full",
      }}
    >
      <Link href={"https://github.com/MrStealYoCat"} _hover={{ textDecoration: "none" }}>
        {showText && <Text variant={"noTextBlur"}>GitHub</Text>}
        {showText && <Text variant={"orangeBold"} marginLeft={"12px"}>GitHub</Text>}
      </Link>
    </Box>
  );
};
const SteamLink = () => {
  const [showText, setShowText] = React.useState(false);
  return (
    <Box
      onPointerEnter={() => setShowText(true)}
      onPointerLeave={() => setShowText(false)}
      as={"button"}
      __css={{
        backgroundImage:
          "https://avatars.cloudflare.steamstatic.com/f176e504626b8d56bfd0a6eca9ba675cdf4d2e53_full.jpg",
        backgroundSize: "224px 224px",
        backgroundRepeat: "no-repeat",
        boxSize: "3xs",
        borderRadius: "full",
      }}
    >
      <Link href={"https://steamcommunity.com/id/MrStealYoCat"} _hover={{ textDecoration: "none" }}>
        {showText && <Text variant={"noTextBlur"}>Steam</Text>}
        {showText && <Text variant={"purpleBold"} marginLeft={"20.5px"}>Steam</Text>}
      </Link>
    </Box>
  );
};
const GitlabLink = () => {
  const [showText, setShowText] = React.useState(false);
  return (
    <Box
      onPointerEnter={() => setShowText(true)}
      onPointerLeave={() => setShowText(false)}
      as={"button"}
      __css={{
        backgroundImage: "https://git.qoto.org/assets/twitter_card-570ddb06edf56a2312253c5872489847a0f385112ddbcd71ccfa1570febab5d2.jpg",
        backgroundSize: "224px 224px",
        backgroundRepeat: "no-repeat",
        boxSize: "3xs",
        borderRadius: "full",
      }}
    >
      <Link href={"https://gitlab.mrstealyocat.com"} _hover={{ textDecoration: "none" }}>
        {showText && <Text variant={"noTextBlur"}>GitLab</Text>}
        {showText && <Text variant={"purpleBold"} marginLeft={"19px"}>GitLab</Text>}
      </Link>
    </Box>
  );
};
const PortainerLink = () => {
  const [showText, setShowText] = React.useState(false);
  return (
    <Box
      onPointerEnter={() => setShowText(true)}
      onPointerLeave={() => setShowText(false)}
      as={"button"}
      __css={{
        backgroundImage: "https://cdn.icon-icons.com/icons2/2407/PNG/512/docker_icon_146192.png",
        backgroundSize: "224px 224px",
        backgroundRepeat: "no-repeat",
        boxSize: "3xs",
        borderRadius: "full",
      }}
    >
      <Link href={"https://portainer.mrstealyocat.com"} _hover={{ textDecoration: "none" }}>
        {showText && <Text variant={"noTextBlur"}>Docker</Text>}
        {showText && <Text variant={"orangeBold"} marginLeft={"11px"}>Docker</Text>}
      </Link>
    </Box>
  );
};
const MinecraftLink = () => {
  const [showText, setShowText] = React.useState(false);
  return (
    <Box
      onPointerEnter={() => setShowText(true)}
      onPointerLeave={() => setShowText(false)}
      as={"button"}
      __css={{
        backgroundImage: "https://iili.io/HDOBlvp.jpg",
        backgroundSize: "224px 224px",
        backgroundRepeat: "no-repeat",
        boxSize: "3xs",
        borderRadius: "full",
      }}
    >
      <Link href={"#"} _hover={{ textDecoration: "none" }}>
        {showText && <Text variant={"noTextBlur"}>Minecraft</Text>}
        {showText && <Text variant={"orangeBold"} marginLeft={"-25px"}>Minecraft</Text>}
      </Link>
    </Box>
  );
};
const Homepage = () => {
  const [jwt, setJwt] = React.useState(Cookies.get("token"));
  const [emailCookie, setEmailCookie] = React.useState(Cookies.get("email"));
  const [getUser, session] = useMutation(GET_USER);
  React.useEffect(() => {
    getUser({
      variables: {
        getUser: {
          jwt: {
            access_token: jwt,
          },
          email: emailCookie,
        },
      },
    });
  }, []);
  if (!session.data) {
    return <>Loading...</>;
  }
  console.log("Session: ", session);

  return (
    <Suspense fallback={"Loading..."}>
      <div className={styles.globe} />
      <div className={styles.container}>
        <main className={styles.main}>
          <Center>
            <Box>
              <Flex width={"90vw"}>
                <Spacer />
                <CustomAvatar
                  email={session.data.getUser.email}
                  id={session.data.getUser.id}
                  name={session.data.getUser.name}
                  role={session.data.getUser.role}
                  jwt={jwt}
                  picture={session.data.getUser.picture}
                />
              </Flex>
              <Heading
                size={"3xl"}
                textAlign={"center"}
                bgGradient="linear(to-l, #7928CA, #FF0080)"
                bgClip="text"
                fontSize="6xl"
                fontWeight="extrabold"
                p={3}
              >
                {'Cat\'s "new" Index Page'}
              </Heading>
              <Heading size={"md"} textAlign={"center"}>
                {"(At least it's not my php page...)"}
              </Heading>
            </Box>
          </Center>
          <Center height={"30vh"}>
            <Flex w={"60vw"}>
              <BlogLink />
              <Spacer />
              <MinecraftLink />
              <Spacer />
              <SteamLink />
            </Flex>
            {/*<Button variant={"ghost"}>*/}
            {/*  <Image src={"multicraft.png"} alt={"Multicraft Dashboard"} />*/}
            {/*</Button>*/}
          </Center>
          <Center height={"40vh"}>
            <Flex w={"60vw"}>
              <GitlabLink />
              <Spacer />
              <PortainerLink />
              <Spacer />
              <GithubLink />
            </Flex>
          </Center>
        </main>
      </div>
    </Suspense>
  );
};
export default Homepage;
