import React, { Suspense } from "react"
import styles from "../styles/Home.module.css"
import { Avatar, Box, Button, Center, Flex, Heading, Image } from "@chakra-ui/react"
import {useNavigate} from "react-router-dom";
import LoginForm from "./LoginForm";
import SignupForm from "./SignupForm";
import ForgotPasswordForm from "./ForgotPassword";

export type WhichContent = {
  which: string
  setWhich: (which: string) => void
}
export const WhichContext = React.createContext<WhichContent>({
  which: "", // set a default value
  setWhich: () => {},
})
export const useWhichContext = () => React.useContext(WhichContext)
const LoginSignup = () => {
  const [which, setWhich] = React.useState<string>("")
  console.log(which)
  const navigate = useNavigate();
  if (which === "login") {
    return (
      <WhichContext.Provider value={{ which, setWhich }}>
        <LoginForm/>
      </WhichContext.Provider>
    )
  }
  if (which === "signup") {
    return (
      <WhichContext.Provider value={{ which, setWhich }}>
        <SignupForm/>
      </WhichContext.Provider>
    )
  }
  if (which === "forgot") {
    return (
      <WhichContext.Provider value={{ which, setWhich }}>
        <ForgotPasswordForm/>
      </WhichContext.Provider>
    )
  }
  return (
    <>
      <Center>
        <Image
          src={"konosuba-dance.gif"}
          alt={"Konosuba party members dancing."}
          borderBottom={"1px"}
        />
      </Center>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.buttonContainer}>
            <Button className={styles.button} __css={{}} onClick={() => setWhich("signup")}>
              <strong>Sign Up</strong>
            </Button>
            <Button className={styles.loginButton} __css={{}} onClick={() => setWhich("login")}>
              <strong>Login</strong>
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}
const Home = () => {
  // const { data: session } = useSession()
  // const router = useRouter()
  // if (session) {() => router.push(Routes.Homepage())}
  // else {() => router.push(Routes.Home())}
  return (
    <>
      <div className={styles.globe} />
      <div className={styles.container}>
        <main className={styles.main}>
          <Center>
            <Box>
              <Heading
                size={"3xl"}
                textAlign={"center"}
                bgGradient="linear(to-l, #7928CA, #FF0080)"
                bgClip="text"
                fontSize="6xl"
                fontWeight="extrabold"
                p={3}
              >
                {"MrStealYoCat"}
              </Heading>
              <Heading size={"md"} textAlign={"center"}>
                {"...Finally learned Web Development"}
              </Heading>
            </Box>
          </Center>
          <LoginSignup />
        </main>
      </div>
    </>
  )
}
export default Home
