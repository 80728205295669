// import Layout from "src/core/layouts/Layout"
// import { LabeledTextField } from "src/core/components/LabeledTextField"
// import { Form, FORM_ERROR } from "src/core/components/Form"
// import { ForgotPassword } from "src/auth/schemas"
// import forgotPassword from "src/auth/mutations/forgotPassword"
// import { useMutation } from "@blitzjs/rpc"
// import { BlitzPage } from "@blitzjs/next"
import { Button } from "@chakra-ui/react"
import styles from "../styles/Home.module.css"
import React from "react"
import { useWhichContext } from "../Login/Login"

const ForgotPasswordForm = () => {
  // const [forgotPasswordMutation, { isSuccess }] = useMutation(forgotPassword)
  const { which, setWhich } = useWhichContext()
  // if (isSuccess) {
  //   return (
  //     <div>
  //       <h2>Request Submitted</h2>
  //       <p>
  //         If your email is in our system, you will receive instructions to reset your password
  //         shortly.
  //       </p>
  //     </div>
  //   )
  // }
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.buttonContainer}>
          <div>
            <h1>Forgot Password</h1>

      {/*<Form*/}
      {/*  submitText="Send Email Instructions"*/}
      {/*  schema={ForgotPassword}*/}
      {/*  initialValues={{ email: "" }}*/}
      {/*  onSubmit={async (values) => {*/}
      {/*    try {*/}
      {/*      await forgotPasswordMutation(values)*/}
      {/*    } catch (error: any) {*/}
      {/*      return {*/}
      {/*        [FORM_ERROR]: "Sorry, we had an unexpected error. Please try again.",*/}
      {/*      }*/}
      {/*    }*/}
      {/*  }}*/}
      {/*>*/}
      {/*  <LabeledTextField name="email" label="Email" placeholder="Email" />*/}
      {/*</Form>*/}
            <div style={{ marginTop: "1rem" }}>Forgot Email Too?</div>
            <div style={{ marginTop: "1rem" }}>
              <Button className={styles.button} __css={{}} onClick={() => setWhich("")}>
                <strong>Cancel</strong>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPasswordForm
