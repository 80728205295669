import React, { Key, Suspense } from "react";
import styles from "../styles/Home.module.css";
import {
  Box, Button, Center, Flex, Heading, Spacer, useToast, Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, useDisclosure, Divider, Select, Tag, Textarea,
} from "@chakra-ui/react";
import { CustomAvatar } from "../Homepage/Homepage";
import { Input } from "@chakra-ui/react";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  AuthInput,
  GetJwtInput,
  GetUserInput,
  Jwt,
  User,
  Post,
} from "../../types/generated";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import PostItem from "./PostItem";

const ALL_USERS = gql`
  query allUsers {
    allUsers {
      name
    }
  }
`;
const ALL_POSTS = gql`
  query allPosts {
    allPosts {
      title
    }
  }
`;
const ALL_CATEGORIES = gql`
  query allCategories {
    allCategories {
      category
    }
  }
`;
const GET_USER = gql`
  mutation getUser($getUser: GetUserInput!) {
    getUser(getUser: $getUser) {
      id
      email
      name
      role
      picture
    }
  }
`;
const CREATE_POST = gql`
  mutation createPost($createPost: CreatePostInput!) {
    createPost(createPost: $createPost) {
      title
      image
      user
      categories
      markdown
    }
  }
`;

const BlogHome = () => {
  const [jwt, setJwt] = React.useState(Cookies.get("token"));
  const [emailCookie, setEmailCookie] = React.useState(Cookies.get("email"));
  const [title, setTitle] = React.useState("");
  const [markdown, setMarkdown] = React.useState("");
  const [categories, setCategories] = React.useState([]);
  const [imageUrl, setImageUrl] = React.useState("");
  const [getUser, userSession] = useMutation(GET_USER);
  const [createPost, postSession] = useMutation(CREATE_POST);
  const users = useQuery(ALL_USERS);
  const posts = useQuery(ALL_POSTS);
  const availableCategories = useQuery(ALL_CATEGORIES);
  React.useEffect(() => {
    getUser({
      variables: {
        getUser: {
          jwt: {
            access_token: jwt,
          },
          email: emailCookie,
        },
      },
    });
  }, []);
  const toast = useToast();
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure()

  if (!userSession.data || users.loading || posts.loading) {
    return <>Loading...</>;
  }
  if (users.error || posts.error) {
    return (
      <>
        Error {users.error?.message} {posts.error?.message}
      </>
    );
  }

  const handleNewPost = () => {
    if (title === '' || markdown === '' || categories.length === 0) {
      return;
    }
    createPost({
      variables: {
        title: title,
        user: userSession.data.user,
        markdown: markdown,
        categories: categories,
      }
    })
      .then(r => {

      })
      .catch(e => {
        console.log(e.message)
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        })

      });
  }
  const resetCreatePost = () => {
    setTitle('');
    setCategories([]);
    setMarkdown('');
    setImageUrl('');
    onClose();
  }

  console.log("Session: ", userSession);
  return (
    <Suspense fallback={"Loading..."}>
      <div className={styles.globe} />
      <div className={styles.container}>
        <Center>
          <Box>
            <Flex width={"90vw"}>
              <Spacer />
              <CustomAvatar
                email={userSession.data.getUser.email}
                id={userSession.data.getUser.id}
                name={userSession.data.getUser.name}
                role={userSession.data.getUser.role}
                jwt={jwt}
                picture={userSession.data.getUser.picture}
              />
            </Flex>
            <Heading
              size={"3xl"}
              textAlign={"center"}
              bgGradient="linear(to-r, blue.500, green.500)"
              bgClip="text"
              fontSize="6xl"
              fontWeight="extrabold"
              p={3}
            >
              {"Cat's Blog"}
            </Heading>
            <Heading size={"md"} textAlign={"center"}>
              {"The Cat-alog of Knowledge"}
            </Heading>
            <Center>
              <Input
                placeholder="Filter Posts"
                w={"40vw"}
                bgColor={"white"}
                mt={10}
              />
              <Button onClick={onOpen}>
                Create Post
              </Button>
              <Modal isOpen={isOpen} onClose={onClose} size={'full'}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Create A Post</ModalHeader>
                  <ModalCloseButton onClick={resetCreatePost}/>
                  <ModalBody>
                    <Flex>Title:</Flex>
                    <Input placeholder="Something Catchy... or not" />
                    <Divider/>
                    <Flex>Categories:</Flex>
                    <Flex>{categories.map((category, index) => {
                      return (
                        <Tag>{category}</Tag>
                      )
                    })}</Flex>
                    <Select placeholder='Select Related Categories' onChange={handleNewPost}>
                      {availableCategories.data.map((category: string) => {
                        return (
                          <option value={category}>{category}</option>
                        )
                      })}
                    </Select>
                    <Divider/>
                    <Flex>Content:</Flex>
                    <Textarea
                      value={value}
                      onChange={handleInputChange}
                      placeholder='My final message. Save the world. ~Whoosh~'
                      size='sm'
                    />
                  </ModalBody>

                  <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={resetCreatePost}>
                      Cancel
                    </Button>
                    <Button>Save and Publish</Button>
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </Center>
          </Box>
          <Flex>
            {/* {posts.data.map((post: Post, key: Key) => (
              <Flex>
                <PostItem {...{ post: post }} />
              </Flex>
            ))} */}
          </Flex>
        </Center>
      </div>
    </Suspense>
  );
};
export default BlogHome;
