// import { AuthenticationError, PromiseReturnType } from "blitz"
// import Link from "next/link"
// import { LabeledTextField } from "src/core/components/LabeledTextField"
// import { Form, FORM_ERROR } from "src/core/components/Form"
// import login from "src/auth/mutations/login"
// import { Login } from "src/auth/schemas"
// import { useMutation } from "@blitzjs/rpc"
// import { Routes } from "@blitzjs/next"
import { Button, FormControl, FormHelperText, FormLabel, Link, useToast } from "@chakra-ui/react"
import styles from "../styles/Home.module.css"
import { useWhichContext } from "./Login"
import { Input } from "@chakra-ui/react";
import { gql, useMutation } from "@apollo/client";
import { AuthInput, GetUserInput } from "../../types/generated";
import {useNavigate} from "react-router-dom";
import React from "react";
import Cookies from 'js-cookie';

// outer createUser is mutation name. inner createUser is mutation argument
// variables below have to match listed variable $newUser
// variables within the create user {} are what is returned.
// For example I send out a password for hashing but get returned a hashedPassword
const AUTH_USER = gql`
    mutation login($login: AuthInput!) {
        login(login: $login) {
            access_token
        }
    }
`
const GET_USER = gql`
    mutation getUser($getUser: GetUserInput!) {
        getUser(getUser: $getUser) {
            id
            email
            name
            role
        }
    }
`

export const LoginForm = () => {
  const storedJwt = localStorage.getItem('token');
  const storedEmail = localStorage.getItem('email');
  const [jwt, setJwt] = React.useState(storedJwt || null);
  const [emailCookie, setEmailCookie] = React.useState(storedEmail || null);
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [authUser, session] = useMutation(AUTH_USER);
  // const [loginMutation] = useMutation(login)
  const { which, setWhich } = useWhichContext()
  const toast = useToast();
  const navigate = useNavigate();

  const handleLogin = () => {
    if (email === '' || password === '') {
      return;
    }
    const auth: AuthInput = {
      email: email,
      password: password,
    }
    authUser({
      variables: {
        login: auth,
      }
    })
      .then(r => {
        setJwt(r.data.login.access_token);
        Cookies.set('token', r.data.login.access_token);
        setEmailCookie(email);
        Cookies.set('email', email);
        navigate('/home');
      })
      .catch(e => {
      console.log(e.message)
      toast({
        title: e.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      })

    });
  }

  const handlegithublogin = () => {
    const auth: AuthInput = {
      email: email,
      password: password,
    }
    authUser({
      variables: {
        login: auth,
      }
    })
      .then(r => {
        setJwt(r.data.login.access_token);
        Cookies.set('token', r.data.login.access_token);
        setEmailCookie(email);
        Cookies.set('email', email);
        navigate('/home');
      })
      .catch(e => {
        console.log(e.message)
        toast({
          title: e.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        })

      });
  }
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.buttonContainer}>
          <div>
            <h1>Login</h1>
              {/*<Link href="/api/auth/github">*/}
              {/*  <Button className={styles.loginButton} __css={{}} onClick={() => handlegithubLogin()}>*/}
              {/*    <strong>Login With Github</strong>*/}
              {/*  </Button>*/}
              {/*</Link>*/}
              <Link href="/api/auth/twitter">
                <Button className={styles.loginButton} __css={{}}>
                  <strong>Login With Twitter</strong>
                </Button>
              </Link>
            <FormControl isRequired>
              <FormLabel>email</FormLabel>
              <Input type='text' value={email} onChange={(e) => setEmail(e.target.value)}/>
              <FormHelperText>Back again huh?</FormHelperText>
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Password</FormLabel>
              <Input type='password' value={password} onChange={(e) => setPassword(e.target.value)}/>
              <FormHelperText>Bold of you to assume I verify your password. </FormHelperText>
            </FormControl>
            <div style={{ marginTop: "1rem" }}>
              <Button className={styles.button} __css={{}} onClick={() => handleLogin()}>
                <strong>Submit</strong>
              </Button>
            </div>
            <div style={{ marginTop: "1rem" }}>Not feeling it:</div>
            <div style={{ marginTop: "1rem" }}>
              <Button className={styles.button} __css={{}} onClick={() => setWhich("signup")}>
                <strong>Sign Up</strong>
              </Button>
              <Button className={styles.button} __css={{}} onClick={() => setWhich("")}>
                <strong>Cancel</strong>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LoginForm
