import React, { Key, Suspense } from "react";
import styles from "../styles/Home.module.css";
import {
  Box,
  Center,
  Editable,
  EditableInput,
  EditablePreview, EditableTextarea,
  Flex,
  Heading,
  Image,
  Spacer,
  Text,
  Textarea
} from "@chakra-ui/react";
import { CustomAvatar } from "../Homepage/Homepage";
import { Input } from "@chakra-ui/react";
import { gql, useMutation, useQuery } from "@apollo/client";
import {
  AuthInput,
  GetJwtInput,
  GetUserInput,
  Jwt,
  User,
  Post,
} from "../../types/generated";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

const ALL_POSTS = gql`
    query allPosts {
        allPosts {
            title
        }
    }
`;
const GET_USER = gql`
    mutation getUser($getUser: GetUserInput!) {
        getUser(getUser: $getUser) {
            id
            email
            name
            firstName
            lastName
            role
            bio
            picture
        }
    }
`;

const ProfileEdit = () => {
  const [jwt, setJwt] = React.useState(Cookies.get("token"));
  const [emailCookie, setEmailCookie] = React.useState(Cookies.get("email"));
  const [getUser, session] = useMutation(GET_USER);
  const posts = useQuery(ALL_POSTS);
  React.useEffect(() => {
    getUser({
      variables: {
        getUser: {
          jwt: {
            access_token: jwt,
          },
          email: emailCookie,
        },
      },
    });
  }, []);
  if (!session.data || posts.loading) {
    return <>Loading...</>;
  }
  if (posts.error) {
    return (
      <>
        Error {posts.error?.message}
      </>
    );
  }
  console.log("Session: ", session);
  return (
    <Suspense fallback={"Loading..."}>
      <div className={styles.globe} />
      <div className={styles.container}>
        <Center>
          <Box>
            <Flex width={"90vw"}>
              <Spacer />
              <CustomAvatar
                email={session.data.getUser.email}
                id={session.data.getUser.id}
                name={session.data.getUser.name}
                role={session.data.getUser.role}
                jwt={jwt}
              />
            </Flex>
            <Heading
              size={"3xl"}
              textAlign={"center"}
              bgGradient="linear(to-r, blue.500, green.500)"
              bgClip="text"
              fontSize="6xl"
              fontWeight="extrabold"
              p={3}
            >
              {session.data.getUser.firstName + "'s Profile"}
            </Heading>
            <Heading size={"md"} textAlign={"center"}>
              <Editable defaultValue={session.data.getUser.bio || "Bio Goes Here"}>
                <EditablePreview />
                <EditableTextarea />
              </Editable>
            </Heading>
            <Box marginLeft={"30%"}>
              <Flex>
                <Editable defaultValue={session.data.getUser.picture || "https://static.wixstatic.com/media/591080_113f52f22b5b45b58d925b1a1269bc0a~mv2.gif"} marginTop={"2%"}>
                  <EditablePreview />
                  <EditableInput />
                </Editable>
              </Flex>
              <Flex>
                <Image src={session.data.getUser.picture} fallbackSrc={"https://static.wixstatic.com/media/591080_113f52f22b5b45b58d925b1a1269bc0a~mv2.gif"}/>
              </Flex>
              <Flex>
                <Editable defaultValue={session.data.getUser.email} marginTop={"2%"}>
                  <EditablePreview />
                  <EditableInput />
                </Editable>
              </Flex>
              <Flex>
                <Editable defaultValue={session.data.getUser.firstName} marginTop={"2%"}>
                  <EditablePreview />
                  <EditableInput />
                </Editable>
              </Flex>
              <Flex>
                <Editable defaultValue={session.data.getUser.lastName} marginTop={"2%"} marginBottom={"10%"}>
                  <EditablePreview />
                  <EditableInput />
                </Editable>
              </Flex>
            </Box>
          </Box>
        </Center>
      </div>
    </Suspense>
  );
};
export default ProfileEdit;
