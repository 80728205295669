import React, { StrictMode } from "react";
import logo from "./logo.svg";
import "./App.css";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Route,
  useNavigate,
  Outlet,
} from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import Login from "./Login/Login";
import Home from "./Login/Login";
import Blog from "./Blog/Blog";
import Homepage from "./Homepage/Homepage";
import { setContext } from "@apollo/client/link/context";
import Cookies from "js-cookie";
import Profile from "./Profile/Profile";
import ProfileEdit from "./Profile/Profile";

const httpLink = createHttpLink({
  uri: "https://graphql.mrstealyocat.com/graphql",
});

const authLink = setContext((operation, previousContext) => {
  const { headers, authorization } = previousContext;
  console.log("Trying to send AuthLink.");
  // get the authentication token from local storage if it exists
  let token = Cookies.get("token");
  // return the headers to the context so httpLink can read them
  if (!token) {
    console.log("no token...");
  }
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export const apolloClient = new ApolloClient({
  //uri: "http://localhost:3001/graphql",
  cache: new InMemoryCache(),
  connectToDevTools: true,
  link: ApolloLink.from([authLink, httpLink]),
  //link: authLink.concat(httpLink),
});

const custom = extendTheme({
  components: {
    Text: {
      variants: {
        greenBold: {
          bgGradient: "linear(to-r, blue.500, green.500)",
          bgClip: "text",
          fontSize: "6xl",
          fontWeight: "extrabold",
          paddingY: "67px",
          // borderRadius: "full",
          // backdropFilter: "blur(4px)",
          whiteSpace: 'nowrap',
          position: "absolute",
          marginTop: "-224px",
        },
        orangeBold: {
          bgGradient: "linear(to-r, red.500, yellow.500)",
          bgClip: "text",
          fontSize: "6xl",
          fontWeight: "extrabold",
          paddingY: "67px",
          // borderRadius: "full",
          // backdropFilter: "blur(4px)",
          whiteSpace: 'nowrap',
          position: "absolute",
          marginTop: "-224px",
        },
        purpleBold: {
          bgGradient: "linear(to-l, #7928CA, #FF0080)",
          bgClip: "text",
          fontSize: "6xl",
          fontWeight: "extrabold",
          paddingY: "67px",
          // borderRadius: "full",
          // backdropFilter: "blur(4px)",
          whiteSpace: 'nowrap',
          position: "absolute",
          marginTop: "-224px",
        },
        blueBold: {
          bgGradient: "linear(to-l, #7928CA, #FF0080)",
          bgClip: "text",
          fontSize: "6xl",
          fontWeight: "extrabold",
          paddingY: "67px",
          // borderRadius: "full",
          // backdropFilter: "blur(4px)",
          whiteSpace: 'nowrap',
          position: "absolute",
          marginTop: "-224px",
        },
        noTextBlur: {
          bgClip: "text",
          fontSize: "6xl",
          fontWeight: "extrabold",
          underline: "none",
          paddingY: "67px",
          borderRadius: "full",
          backdropFilter: "blur(4px)",
          whiteSpace: 'nowrap',
          position: "relative",
        },
      },
    },
  },
});
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route>
      <Route path={"/"} element={<Login />} />
      <Route path={"home/"} element={<Homepage />} />
      <Route path={"blog/"} element={<Blog />} />
      <Route path={"profile/"} element={<ProfileEdit />} />
    </Route>,
  ),
);
function App() {
  return (
    <StrictMode>
      <ChakraProvider theme={custom}>
        <ApolloProvider client={apolloClient}>
          <RouterProvider router={router} />
        </ApolloProvider>
      </ChakraProvider>
    </StrictMode>
  );
}

export default App;
